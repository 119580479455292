<template>
  <div class="subBg">
     <h2 class="subBanner"><img src="@/assets/img/sub_board.png" alt=""/></h2>
     <section>
       <div class="snavi">
         <!--img class="mr10 ml10" src="@/assets/img/snavhomeicon.png" />
         <img src="@/assets/img/snavicon.png" />
         <span class="ml10 b">{{ $t('front.common.board') }}</span-->
         <h2 class="subtxt"><img src="@/assets/img/icon_star.svg" />{{ $t('front.common.board') }}</h2>
       </div>
       <router-view />
     </section>
  </div>

</template>

<script>
export default {
  name: 'Board'
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
